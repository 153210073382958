import * as React from "react"
import { Link } from "gatsby"
function Footer() {
  return (
    <footer className="my-4 px-10 md:px-20 lg:px-24 xl:px-40 m-auto flex justify-between">
      <div>© {new Date().getFullYear()} Hudson Community Television</div>
      <div>
          <Link to="/help">Help</Link>
      </div>
    </footer>
  )
}

export default Footer
