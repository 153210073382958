import { Link } from "gatsby"
import { default as React } from "react"
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
} from "react-instantsearch-dom"

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits

  return hitCount > 0 ? (
    <div className="HitCount">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  ) : null
})

const PageHit = ({ hit }) => (
  <Link to={"/" + hit.objectID}>
    <div className="grid grid-cols-5 gap-6 bg-gray-200 rounded-md p-2 items-center">
      <Link to={"/" + hit.objectID} className="col-span-2">
        <img className="rounded-md" src={hit.thumbnail} alt={hit.title} />
      </Link>
      <div className="col-span-3 font-bold">
        <Link to={"/" + hit.objectID} className="grid grid-cols-2">
          <h4 className="col-span-2">
            <Highlight attribute="title" hit={hit} tagName="mark" />
          </h4>
          <p className="font-normal text-sm">{hit.duration}</p>
        </Link>
        <Snippet attribute="description" hit={hit} tagName="mark" />
      </div>
    </div>
  </Link>
)

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <Hits className="Hits" hitComponent={PageHit} />
  </Index>
)

const SearchResult = ({ indices, className }) => (
  <div className={className}>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
)

export default SearchResult
