import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { CalendarIcon } from "@heroicons/react/outline"
import Logo from "../images/logo.png"
import Search from "./search"
const searchIndices = [{ name: `Videos`, title: `Videos` }]


const Header = ({ siteTitle }) => (
  <header className="mx-auto px-10 my-8 font-sans md:px-20 lg:px-24 xl:px-40">
    <div className="flex justify-between mb-10">
      <div>
        <Link to="/">
          <img className="w-48 h-auto mb-0" src={Logo} alt={siteTitle} />
        </Link>
      </div>
      <div className="flex flex-nowrap gap-4 items-center">
        {/* <Link to="/page-2" alt="Calendar">
          <CalendarIcon className="w-8" />
        </Link> */}
        <Search indices={searchIndices} />
      </div>
    </div>
    <div className="full-width px-10 md:px-20 lg:px-24 xl:px-40 flex gap-6 overflow-x-auto font-black text-lg hide-scroll uppercase flex-nowrap whitespace-nowrap flex-row flex-shrink-0">
      <Link to="/" activeClassName="active">
        Home
      </Link>
      <Link to="/shows" activeClassName="active">
        Shows
      </Link>
      <Link to="/shows/4989434" activeClassName="active">
        Sports
      </Link>
      <Link to="/videos" activeClassName="active whitespace-nowrap">
        All Videos
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
